import "./ArtistSeparator.scss";

const ArtistSeparator = () => {
  const artistList = [
    "TOXYBLUE",
    "U.NOESCP",
    "ILLUMINATEK",
    "MEDOK",
    "STOWN",
    "GEOTIK",
    "2DGAZ",
    "MYSTIMORPHIK",
    "TERAPEUTEK",
    "PSILOCHOCK",
    "DELCHE",
    "2DGAZ",
    "GUZ",
    "COCO",
    "MEDOK",
    "STANISLAWA",
    "RAVN",
    "OPAK CREW",
    "TOUBAB",
    "PHIL",
    "TERAPEUTEK",
    "TOMMY",
    "NESIA",
    "STOWN",
    "BRIOUCH-K",
    "PRAYZ",
    "INSTIX",
    "GEUBRAID",
    "GEOTIK",
    "U.NOESCP",
    "MYSTIMORPHIK",
    "DAÄNYA",
    "ETAT ZERO",
    "RK PROJECT",
    "SAME",
    "CANTIK",
    "SOUL3D",
    "OFRN",
    "BASSKEZ",
    "DYNOSON",
    "KOLASIN",
    "ILLEGAL BROTHER",
    "R.AVA",
    "WHYAM'ME",
    "ROTOR",
    "SISLEY & BENONESHOT",
    "YOTEK",
    "HAFS",
    "ARTK",
  ];

  const loopingArtistList = [...artistList, ...artistList];

  return (
    <section className="artistSeparator">
      <div className="scrolling-wrapper">
        {loopingArtistList.map((artist, index) => (
          <span className="artistName" key={index}>
            {artist}
            {"\u00A0+\u00A0"}
          </span>
        ))}
      </div>
    </section>
  );
};

export default ArtistSeparator;
